@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;900&display=swap');

body {
  background: radial-gradient(50% 50% at 50% 50%, #124B7C 0%, #041533 100%);
  color: #fff;
  font-family: 'Nunito', sans-serif;
  overflow-x:hidden;

}

p {
  font-size: 1.2rem;
  font-weight:700;
}

.start {
  background: linear-gradient(95.01deg, #85D0CE 51.21%, #208380 100%);
  height: 100vh;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-weight:900;
    font-size:1.5rem;
    @media only screen and (max-width: 600px) {
      font-size:1.1rem;
    }
  }

  .play-btn {
    width: 120px;
    height: 120px;
    background: radial-gradient(rgba(215, 27, 90, .9) 60%, rgba(255, 255, 255, 1) 62%);
    border-radius: 50%;
    position: relative;
    display: block;
    margin: 30px auto 0 auto;
    box-sizing: revert;
    color: #fff;
    line-height:120px;
    font-size:1.2rem;
    font-weight:900;

    &:hover {
      background: radial-gradient(rgba(215, 27, 90, 1) 60%, rgba(255, 255, 255, 1) 62%);
    }
  }

  a {
    text-decoration: none;;
  }
  
  /* triangle */
  /*
  .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-50%);
    transform: translateX(-40%) translateY(-50%);
    transform-origin: center center;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 25px solid #fff;
    z-index: 100;
    -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  */
  

}

.heading  {
  height:20%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 10%;
  position:relative;
  h1 {
    position:relative;
    font-weight:900;
    z-index:10;
    display:inline-block;
    text-shadow: 2px 2px #000000;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  @media only screen and (max-width: 600px) {
    padding: 0 5%;
    h1 {
      font-size:1.3rem;
    }
  }

  .fcountdown {
    position:absolute;
    font-size:4rem;
    bottom: -100px;
    font-weight:900;
  }

}



.content {
  height: 65%;
  position:relative;
}

.progressbar {
  height: 10%;
  position:relative;
  .progress {
    position: absolute;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
    z-index:2;
  
    .progress-bar {
      border-radius: 20px;
    }
    
  }

}


.booster {
  position:absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index:34;
  h1 {
    font-weight:900;
    font-size:2.5rem;
    @media only screen and (max-width: 600px) {
      font-size:2rem;
    }

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  }
}

.final-countdown {
  position: fixed;
  top: 20%;
  right: 0;
  left: 0;
  z-index: 50;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  position:absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom:15px;
  height:25px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

  @media only screen and (max-width: 600px) {
    height:20px;
  }
}


.App {
  width: 100%;
  height: 100vh;
  text-align:center;


}

.action-button {
  background:url(./assets/img/button.png) no-repeat;
  background-size: 350px 350px;
  text-align: center;
  cursor: pointer;
  margin: 0;
  //transition: all 0.5s ease-out;
  position: absolute;
  left: 50%;
  top: 50%;
  transform:translate(-50%,-50%);
  width: 350px;
  height:350px;
  border: 0;
  padding: 0 !important;
  
  @media only screen and (max-width: 600px) {
    background-size: 200px 200px !important;
    width:200px;
    height:200px;
  }


  &:active{
    background:url(./assets/img/button_on.png) no-repeat;
    background-size: 350px 350px;
    width: 350px;
    height:350px;
    @media only screen and (max-width: 600px) {
      background-size: 200px 200px !important;
      width:200px;
      height:200px;
    }
  }

  &.loading {
    pointer-events:none;
    //opacity:0.2;
  }
}

.App.escapebox {
  .content {
    .action-button:nth-child(1) {
      background:url(./assets/img/button_turk.png) no-repeat;
  
      &:active {
        background:url(./assets/img/button_turk_on.png) no-repeat;
      }
    }

    .action-button:nth-child(2) {
      background:url(./assets/img/button_yellow.png) no-repeat;
      &:active {
        background:url(./assets/img/button_yellow_on.png) no-repeat;
      }
    }
  }
}


.App.txtcolor {

  h1 {
    color: #fde571;
  }

  .content {
    .action-button:nth-child(1) {
      background:url(./assets/img/button_yellow.png) no-repeat;
  
      &:active {
        background:url(./assets/img/button_yellow_on.png) no-repeat;
      }
    }

    .action-button:nth-child(2) {
      background:url(./assets/img/button_turk.png) no-repeat;
      &:active {
        background:url(./assets/img/button_turk_on.png) no-repeat;
      }
    }
  }


}


.App.redcolor {

  .content {
    .action-button:nth-child(2) {
      background:url(./assets/img/button_yellow.png) no-repeat;
      &:active {
        background:url(./assets/img/button_yellow_on.png) no-repeat;
      }
    }
    .action-button:nth-child(3) {
      background:url(./assets/img/button_turk.png) no-repeat;
      &:active {
        background:url(./assets/img/button_turk_on.png) no-repeat;
      }
    }
  }

}


.video-container {

  video {  
    width:400px;
    height:400px;
    @media only screen and (max-width: 600px) {
      width:100%;
      height:100%;
    }

  }

  &:before {
    /*
    content: '';
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, rgba(18, 75, 124,0.7) 0%, rgba(4, 21, 51,0.7) 100%);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    */
  }
}

form {
  width: 300px;
  margin: 0 auto;
  input {
    display: block;
    width: 100%;
    padding: 10px 15px;
  }

  .btn {
    display:block;
    width: 100%;
    border: 2px solid #fff;
    margin-top:5px;
  }
}


$primary: red;
$body-bg: #000;
$body-color: #fff;
$btn-link-color: #fff;
$font-family-sans-serif:  'Nunito', sans-serif;
$font-family-serif:       'Nunito', sans-serif;


// Temp
.treat {
  --scale-x: 0;
  --scale-y: 0;
  pointer-events: none;
  display: block;
  position: absolute;
  top: 0;
  left: calc(50% - .5rem);
  border-radius: 50%;
  width: 1em;
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5vmin;
  transform: 
    translate(
      calc( var(--x) * 1px ), 
      calc( var(--y) * 1px )
    ) 
    translate(-50%, -50%);
  pointer-events: none;
  animation: 
    treat-enter .1s ease-in backwards, 
    treat-exit 300ms linear 
    calc( (var(--lifetime, 3000) * 1ms) - 300ms) 
    forwards;

  @keyframes treat-enter {
    from {
      opacity: 0;
    }
  }
  @keyframes treat-exit {
    to {
      opacity: 0;
    }
  }
  
  .inner {
    animation: inner-rotate .6s linear infinite;
    transform: rotate( 
      calc(-1turn * var(--direction) )
    ); 
    @keyframes inner-rotate {
      to { 
        transform: none;
      } 
    }
  }
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

}


.duplicated {
  //transition: all 0.5s ease-out;
}

@import '~bootstrap/scss/bootstrap.scss';